header {
  .nav-links {
    li {
      &:hover {
        color: var(--text-white-l);
      }

      & > div {
        gap: 8px;
      }

      a {
        &.active {
          color: var(--text-white-l);
          font-weight: 700;
        }
      }
    }
  }
}

.solutions-menu-trigger {
  cursor: pointer;
  position: relative;
  &:hover {
    .solutions-dropdown {
      opacity: 1;
      visibility: visible;
      transform: translateY(0px);

      transition: all ease 0.5s;

      @media (max-width: 1199px) {
        height: auto;
        transform: none !important;
        transition: none !important;
      }
    }
  }
}
.company-menu-trigger {
  cursor: pointer;
  position: relative;
  &:hover {
    .company-dropdown {
      opacity: 1;
      visibility: visible;
      transform: translateY(0px);

      transition: all ease 0.5s;

      @media (max-width: 1199px) {
        height: auto;
        transform: none !important;
        transition: none !important;
      }
    }
  }
}

.company-dropdown,
.solutions-dropdown {
  display: flex;
  border-radius: 8px;
  background: #fff;
  flex-direction: column;
  gap: 8px;
  min-width: 220px;
  top: 35px;
  margin-top: 8px;

  // transition-delay: 0.5s;

  // @media (max-width: 1199px) {
  //   height: 0;
  //   padding: 0 !important;
  //   transform: none !important;
  //   transition: none !important;
  // }

  @media (min-width: 1200px) {
    padding: 16px !important;
    margin-top: 0;
    display: flex;
    position: absolute;
    opacity: 0;
    visibility: hidden;
    transform: translateY(10px);
    transition: all ease 0.5s;
    gap: 12px;
  }

  a:hover {
    color: var(--text-primary-l) !important;
  }

  a.active {
    color: var(--text-primary-l) !important;
  }
}
