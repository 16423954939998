.main-header,
.main-footer {
  background: #150e12;
  backdrop-filter: blur(12px);

  .header-brand-logo {
    max-width: 174px;
    width: 100%;

    @media (min-width: 768px) {
      max-width: 206px;
    }

    img {
      width: 100%;
    }
  }
}

.main-header {
  top: 0;
  position: fixed;
  width: 100%;
  transition: top 0.4s ease-out;
  z-index: 996;
  padding: 12px 0;

  @media (min-width: 768px) {
    padding: 18px 0;
  }

  @media (min-width: 1200px) {
    padding: 20px 0;
  }
  &.visible {
    // display: block;
    top: 0;
    transition: top 0.4s ease-out;
  }

  &.hidden {
    // display: none;
    top: -110px;
    transition: top 0.4s ease-out;
  }

  .container {
    align-items: center;
    justify-content: space-between;
    display: flex;
  }
}
