.container {
  // max-width: 600px;
  margin: 0 auto;
  width: 100%;
  padding: 0 24px;

  @media (min-width: 768px) {
    // max-width: 600px;
    padding: 0 64px;
  }

  @media (min-width: 1024px) {
    max-width: 768px;
    padding: 0;
  }

  @media (min-width: 1200px) {
    max-width: 1032px;
  }

  @media (min-width: 1440px) {
    max-width: 1416px;
    padding: 0 64px;
  }

  @media (min-width: 1500px) {
    padding: 0;
  }
}

//
body {
  background: var(--bg-surface-d);
}

// text related
.text-uppercase {
  text-transform: uppercase;
}
.text-center {
  text-align: center;
}

// spacings
.section-padding-top {
  padding-top: 40px;

  @media (min-width: 768px) {
    padding-top: 80px;
  }

  @media (min-width: 1200px) {
    padding-top: 160px;
  }
}
.section-padding-bottom {
  padding-bottom: 40px;

  @media (min-width: 768px) {
    padding-bottom: 80px;
  }

  @media (min-width: 1200px) {
    padding-bottom: 160px;
  }
}

.section-padding-y {
  padding: 40px 0;

  @media (min-width: 768px) {
    padding: 80px 0;
  }

  @media (min-width: 1200px) {
    padding: 160px 0;
  }
}

.mt-80 {
  margin-top: 40px;

  @media (min-width: 768px) {
    margin-top: 80px;
  }
}

.mt-24 {
  margin-top: 24px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-120 {
  margin-top: 80px;

  @media (min-width: 768px) {
    margin-top: 120px;
  }
}

.pt-120 {
  padding-top: 120px;
}

.col-gap-24 {
  column-gap: 24px;
}

// zindex

.z-index-2 {
  z-index: 2;
}

// sizes
.w-100 {
  width: 100%;
}
.h-100 {
  height: 100%;
}

.h-100vh {
  height: 100vh;
}

.w-100vw {
  width: 100vw;
}

// flex
.d-flex {
  display: flex;
}
.flex-all-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-align-center {
  display: flex;
  align-items: center;
}

.flex-column {
  flex-direction: column;
}

// positions
.position-relative {
  position: relative !important;
}

// overflow

// overrides
.animate__fadeInSkew {
  animation-name: fadeInSkew !important;
}
.animate__fadeInUp {
  animation-name: fadeInUpNew !important;
}
.animate__fadeInLeft {
  animation-name: fadeInUpCard !important;
}
.animate__fadeInRight {
  animation-name: fadeInUpCard !important;
}

@keyframes fadeInSkew {
  0% {
    opacity: 0;
    transform: perspective(30rem) rotateX(3deg);
  }
  to {
    opacity: 1;
    transform: rotateX(0deg);
  }
}

@keyframes fadeInUpNew {
  0% {
    opacity: 0;
    transform: translateY(40%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInUpCard {
  0% {
    opacity: 0;
    transform: translateY(10%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInLeftNew {
  0% {
    opacity: 0;
    transform: translateX(-30%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInRightNew {
  0% {
    opacity: 0;
    transform: translateX(30%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

main {
  margin-top: 88px;
  z-index: 3;
  position: relative;

  @media (min-width: 1200px) {
    margin-top: 90px;
  }
}

.inner-page {
  footer {
    opacity: 1 !important;
  }
}

html.lenis,
html.lenis body {
  height: auto;
}

.lenis.lenis-smooth {
  scroll-behavior: auto !important;
}

.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}

.lenis.lenis-stopped {
  overflow: hidden;
}

.lenis.lenis-smooth iframe {
  pointer-events: none;
}

.bodyScrollLock {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.transform-null {
  transform: translate3d(0, 0, 0);
}

.greyscale {
  filter: grayscale(100%);
  pointer-events: none;
  // cursor: default;
  opacity: 0.3;
}

sup {
  font-size: 10px;
}

.from-tab {
  display: none;

  @media (min-width: 768px) {
    display: block;
  }
}

.demo-page {
  header {
    .web-btn {
      filter: grayscale(1);
      pointer-events: none;
      opacity: 0.7;
      cursor: default;
    }
  }
}
