.main-footer {
  background: var(--bg-variant2-l);
  backdrop-filter: blur(3.5px);
  padding: 40px 0;

  position: sticky;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 0;

  .container {
    justify-content: space-between;
    display: flex;
    align-items: flex-start;
  }

  .company-dropdown,
  .solutions-dropdown {
    display: none;
  }
}
