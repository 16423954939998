.icon-wrap-default {
  background: transparent;
  border-radius: none;
}

.icon-wrap-bg {
  background-color: #595959;
  border-radius: 100%;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 1200px) {
    width: 32px;
    height: 32px;
  }
}
