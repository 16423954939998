.side-drawer {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 998;
  height: 100vh;
  width: 70%;
  background: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  padding: 40px 24px;

  .nav-links {
    gap: 20px;
    display: flex;
    flex-direction: column;
  }

  button {
    margin-top: 40px;
  }

  .icon-wrap-default {
    position: absolute;
    top: 16px;
    right: 16px;
  }
}
