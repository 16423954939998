.terms-policy-list {
  display: flex;
  align-items: center;
  gap: 48px;
}

.copyright-title {
  margin-top: 8px;
}

footer {
  .main-navigation__header-nav {
    display: flex;
    gap: 24px;
    flex-direction: column;
    margin-top: 36px;

    @media (min-width: 768px) {
      flex-direction: row;
    }

    @media (min-width: 1200px) {
      margin-top: 0px;
    }

    ul {
      min-width: 216px;
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  }

  .container {
    @media (max-width: 1199px) {
      align-items: flex-start !important;
    }
    flex-wrap: wrap;
    .footer-logo {
      @media (max-width: 767px) {
        width: 100%;
      }

      img {
        max-width: 170px;

        @media (min-width: 768px) {
          max-width: 230px;
        }
      }
    }

    // ul {
    //   flex-direction: column;

    //   align-items: flex-start !important;
    //   gap: 24px !important;
    //   @media (max-width: 767px) {
    //     margin-top: 40px;
    //   }

    //   @media (min-width: 1200px) {
    //     flex-direction: row;
    //   }
    // }
  }
}
