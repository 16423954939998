.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.90);
  z-index: 997;
  transition: all ease 0.5s;
}
